:root {
    --main-color: #c0392b;
    --body-bg: #181616;
    --box-bg: #221f1f;
    --text-color: #ffffff;

    --nav-height: 60px;
    --space-top: 30px;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

body {
    font-family: "Cairo", sans-serif;
    background-color: var(--body-bg);
    color: var(--text-color);
    padding-top: var(--nav-height);
}

.movie-item {
    text-decoration: none;
    color: unset;
    
}

img {
    max-width: 100%;
}
.movie-item {
    text-transform: uppercase;
    font-weight: 700;
}

.nav-menu a:hover {
    color: var(--main-color);
}

.nav-menu a.btn:hover,
.movie-item.logo:hover,
.movie-item.movie-item:hover {
    color: unset;
}


.hero-slide-item-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--text-color);
    display: flex;
}



.item-content-title {
    font-size: 5rem;
    line-height: 5rem;
    font-weight: 900;
}

.movie-infos {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: calc(var(--space-top) / 2);
}

.movie-info {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
}

.movie-info span {
    margin-left: 5px;
}

.movie-info i {
    color: var(--main-color);
}

.movie-info ~ .movie-info {
    margin-left: 5px;
}

.item-content-description {
    font-size: 1.15rem;
    margin-top: var(--space-top);
    line-height: 1.5rem;
}

.item-action {
    margin-top: var(--space-top);
}

.datarow{
    position: relative;
}

.datarow.owl-nav button i {
    font-size: 3rem;
}

.datarow.owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.datarow.owl-nav .owl-prev {
    position: absolute;
    left: 5px;
}

.datarow.owl-nav .owl-next {
    position: absolute;
    right: 5px;
}



.movie-item {
    display: block;
    position: relative;
    overflow: hidden;
    padding-top: 140%;
    cursor: pointer;
}

.movie-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform 0.3s linear;
}

.movie-item:hover img {
    transform: scale(1.2);
}

.movie-item-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.8);
}

.movie-item-content::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
}

.movie-item-title {
    color: var(--text-color);
    font-size: 1.5rem;
    position: relative;
    font-weight: 700;
}

.section {
    padding-top: 80px;
}

.section-header {
    margin-bottom: 30px;
    padding-left: 20px;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 700;
    border-left: 4px solid var(--main-color);
    display: flex;
    align-items: center;
}







.ul{
    float: left;
}

.movie-item{
    height: 300px;
    width: 200px;
   
}
@media screen and (max-width: 940px) {
    .movie-item{
        font-size: 10px;
        height: 200px;
        width: 100px;
       
    }
  }